import React from "react"
import Layout from "../components/layout"
import './termsAndPolicy.scss'


const Policy = () => {

  return (
    <Layout>
      <div className="wrapper-section-terms-policy">
        <h1>POLÍTICA DE PRIVACIDADE ("Política")</h1>
        <p>
          <i>Data da última atualização: 25 de julho de 2022</i>
        </p>
        <p>
          Aqui, quando falarmos <b>THEIA</b> (ou "Theia"), estaremos nos referindo
          tanto à Alessa Brasil Tecnologia Ltda., sediada no município de
          São Paulo/SP, inscrita no CNPJ/MF sob o número 33.855.879/0001-02,
          como também a outras empresas, ou sociedades, afiliadas à esta
          empresa. Também, poderá ser referente aos serviços prestados, a
          serem descritos a seguir.
        </p>
        <p>
          A <b>THEIA</b> fornece aos usuários acesso a produtos e serviços próprios
          ou de terceiros ("Serviços"), por meio de aplicativos em
          dispositivos móveis, <i>website</i>, entre outros ("Plataforma Theia",
          "Plataforma" ou "Theia" mesmo), em acordo com os Termos de Uso
          ("Termos").
        </p>
        <p>
          Recomendamos veementemente a leitura com muita atenção da
          Política, pois, afinal, ela é referente a como a Theia trata os
          &nbsp;<b><u>seus</u></b> dados pessoais. Apesar de longo, nos esforçamos para fazer o
          texto da Política ser o mais claro possível. Ressaltamos que o
          tratamento de dados pessoais é uma condição necessária para a
          utilização da Plataforma, pois, afinal, não podemos te ajudar e
          prestar nossos Serviços a você sem sabermos suas informações. Em
          outras palavras, <b>caso você não concorde com a integralidade do
          descrito nesta Política, você não poderá utilizar nossos Serviços</b>.
        </p>
        <p>
          Caso tenha quaisquer dúvidas, reclamações ou elogios a respeito do
          tratamento de dados pessoais, não hesite em mandar um <i>e-mail</i> para
          o endereço dpo@theia.com.br.
        </p>
        <p>
          Na tentativa de deixar esta Política mais clara possível para o
          leitor (e titular dos dados pessoais), a separamos em tópicos,
          conforme se seguem:‍
        </p>

        <ol className="list-number">
          <li>O que são dados pessoais e qual sua importância?;</li>

          <li>
            Qual a necessidade e utilidade de uma Política de Privacidade?;
          </li>

          <li>Para qual finalidade há uso e compartilhamento de dados?;</li>

          <li>
            Quais dados pessoais a Theia vai coletar e como eles vão ser
            utilizados?;
          </li>

          <li>E os dados pessoais sensíveis?;</li>

          <li>
            Como será feito o armazenamento e segurança dos dados? Também,
            por quanto tempo eles ficarão armazenados?;
          </li>

          <li>
            E esses tratamentos serão somente no Brasil, no ambiente Theia?;
          </li>

          <li>
            O que a Theia vai fazer quando quiser alterar ou atualizar esta
            Política?;
          </li>

          <li>Quais os direitos do titular dos dados?;</li>
          <li>Transferência de Dados para Terceiros</li>
        </ol>

        <p>Completada a listagem, vamos às respostas:</p>

        <dl>
          <dt>O que são dados pessoais e qual sua importância?;</dt>
          <dd>

            <p>
              Antes de falarmos de dados pessoais, vamos falar rapidamente sobre
              a legislação vigente. A Lei 13.709/2018, também conhecida como Lei
              Geral de Proteção de Dados ("LGPD") está em vigor desde
              18/09/2020, e ela dispõe sobre o tratamento de dados pessoais e
              objetiva proteger os direitos fundamentais de privacidade e
              liberdade das pessoas físicas. O texto na íntegra pode ser
              acessado&nbsp;
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm"
                target="_blank"
                className="cookies-link"
              >
                aqui
              </a>
              . Ou seja, essa lei, baseada na lei europeia de proteção de dados
              (conhecida como GDPR), veio para regulamentar a forma como os
              dados pessoais são tratados (por tratamento entende-se qualquer
              operação realizada com informações de pessoas físicas).
              <p>
                Nos termos da lei (artigo 5), os dados pessoais podem ser descritos como:
              </p>
              <ol className="sub-list-lower-alpha">
                <li>
                  dado pessoal: informação relacionada a pessoa física identificada
                  ou identificável (como nome, endereço, telefone, e-mail, RG, CPF,
                  fotos, entre outros);
                </li>
                <li>
                  dado pessoal sensível: dado pessoal sobre
                  origem racial ou étnica, convicção religiosa, opinião política,
                  filiação a sindicato ou a organização de caráter religioso,
                  filosófico ou político, dado referente à saúde ou à vida sexual,
                  dado genético ou biométrico, quando vinculado a uma pessoa física;
                </li>
                <li>
                  dado anonimizado: dado relativo a titular que não possa ser
                  identificado, considerando a utilização de meios técnicos
                  razoáveis e disponíveis na ocasião de seu tratamento (ou seja, é
                  um dado que foi desassociado do titular);
                </li>
              </ol>
              <p>
                Da forma mais sucinta
                possível, eles são importantes pois <u>eles são relativos a você e
                são seus</u>. Assim, já que eles são seus, nada mais justo e correto
                de você ter plena ciência de quem acessa seus dados, quais dados
                são acessados, como eles são utilizados, onde são armazenados,
                entre outros – tudo isso com sua plena ciência e anuência. Caso
                você se não queira mais que seus dados sejam tratados por aquela
                pessoa ou empresa específica, você tem total direito e respaldo da
                lei para revogar seu consentimento, mas esses pontos serão
                tratados mais à frente.
              </p>
            </p>
          </dd>

          <dt>
            Qual a necessidade e utilidade de uma Política de Privacidade?;
          </dt>
          <dd>
            <p>
              A Política de Privacidade é o documento que esclarece e formaliza
              as formas com que a Theia realizará os tratamentos (coleta,
              armazenamento, compartilhamento, proteção, exclusão, análises,
              processamento, ou seja, qualquer operação que possa ser realizada
              com as suas informações) dos dados pessoais coletados de você
              (além de como estes dados serão coletados). Além disso, serão
              detalhadas quais as finalidades de uso desses dados coletados,
              quais são os embasamentos legais ("Bases Legais") para podermos
              utilizar estes dados nas finalidades que queremos, e quais formas
              você poderá fazer uso dos seus direitos, como titular dos dados.
            </p>
            <p>
              Em palavras menos técnicas, a Política é útil para garantir que
              estamos cumprindo a legislação de proteção de dados, estamos
              cumprindo com o acordo firmado com você (de forma respeitosa,
              ética e transparente, afinal, você nos confiará dados pessoais e
              sensíveis, incluindo informações de saúde e família), e nos
              comprometendo a empregar nossos melhores esforços para manter seus
              dados seguros e com usos bem definidos.
            </p>
          </dd>

          <dt>Para qual finalidade há uso e compartilhamento de dados?;</dt>
          <dd>

            <p>
              De modo geral, precisamos dos seus dados para poder fornecer os
              Serviços da melhor forma possível para você, na Plataforma, e
              podemos realizar o compartilhamento dos seus dados em algumas
              circunstâncias, a exemplo de: atendimento a órgãos governamentais,
              processos judiciais, para conseguirmos prestar nossos serviços
              (dado que utilizamos ferramentas de terceiros), e, também, caso
              você queira que seus dados sejam compartilhados.
            </p>
            <p>
              Queremos deixar
              claro, entretanto, que a Theia, em hipótese alguma, não vende,
              compartilha, transmite, cede, troca, licencia ou comercializa de
              qualquer forma seus dados pessoais identificáveis com outras
              empresas, companhias, indivíduos, grupos ou organizações fora da
              Theia.
            </p>
            <p>
              Para sermos mais transparentes, vamos listar, mais
              detalhadamente, as finalidades e as circunstâncias em que seja
              possível o compartilhamento dos dados pessoais. Bom, vamos às
              finalidades de uso dos dados pessoais, ou seja, por quais motivos
              é importante termos acesso aos seus dados:
            </p>

            <ol className="sub-list-roman">
              <li>
                Para fornecermos, mantermos e prestarmos, da melhor maneira
                possível, um bom Serviço em nossa Plataforma, ou seja, cumprir
                com o que nos comprometemos a te entregar, e cumprir, também,
                com as solicitações de Serviços que você fizer na Plataforma;
              </li>
              <li>
                Para garantir e permitir que os especialistas com os quais você
                terá contato tenham pleno acesso e conhecimento do seu
                histórico, estado de saúde e prontuário eletrônico, atualizados
                estes com informações atualizadas e relevantes, além de permitir
                que os mesmos especialistas possam atualizar seus registros e
                histórico de saúde, incluindo o registro em prontuário
                eletrônico, facilitando a coordenação de cuidados (ou, nos
                termos da lei, pela tutela de saúde);
              </li>
              <li>
                Para, unindo as questões da tutela de saúde com cumprimento do
                acordo com a Theia, sistematizar e organizar suas informações de
                saúde e perfil, incluindo prontuário, histórico de saúde e
                informações próprias e de dependentes. Assim, seremos mais
                assertivos, eficientes e organizados na prestação de Serviços, o
                que também te beneficiará, pois, desta forma, poderemos promover
                estudos e análises que podem auxiliar em tratamentos,
                prognósticos, diagnósticos, prevenção e enriquecimento do seu
                histórico de saúde;
              </li>
              <li>
                Para facilitar a sua própria gestão, acompanhamento e
                visibilidade da sua própria saúde e informações no geral,
                incluindo prontuário e histórico de atendimentos;
              </li>
              <li>
                Para que possamos nos comunicar com você de qualquer forma
                (e-mail, SMS, notificações, anúncios, telefone, redes sociais),
                incluindo marketing, lembretes, recomendações, dado o seu
                consentimento para comunicação presente ao aceitar a Política;
              </li>
              <li>
                Para que você consiga adquirir Serviços ou contratar planos
                dentro da Plataforma, bem como poder receber as faturas ou Notas
                Fiscais referentes aos atendimentos que realizar;
              </li>
              <li>
                Para podermos, ainda no intuito de melhorar a qualidade dos
                Serviços prestados, realizar estudos estatísticos e análises
                agregados e anônimos sobre o funcionamento, uso dos Serviços,
                acessos e funcionalidades da Plataforma;
              </li>
              <li>
                Para podermos realizar estudos e análises agregados e anônimos a
                respeito de saúde;
              </li>
              <li>
                Para sua segurança, pois mediante os dados, podemos identificar,
                detectar ou identificar tentativas lesivas, de fraudes, ilegais
                ou mesmo suspeitas, e, assim, podemos agir, a fim de minimizar
                nossos riscos (incluindo os seus);
              </li>
              <li>
                Para cumprir com as obrigações legais, em especial com a
                legislação vigente (principalmente a LGPD), sobre tratamento
                (coleta, compartilhamento e retenção de dados), que especifica
                critérios sobre o tratamento de dados e atendimentos a
                autoridades regulatórias e governamentais;
              </li>
              <li>
                Para poder dirimir eventuais disputas e litígios administrativos
                ou judiciais, em concordância com a legislação, em defesa tanto
                de você quanto da Theia;
              </li>
              <li>
                Para podermos realizar e veicular pesquisas de satisfação com
                nossos usuários (ou seja, você) sobre a qualidade dos Serviços
                prestados;
              </li>
              <li>
                Para personalizar sua experiência na Plataforma e nos Serviços
                que prestamos, baseado nos seus padrões de uso (maneira que
                interage com a Plataforma, assuntos de maior interesse,
                especialidades que mais se interessa, conteúdos que acessa e
                lê);
              </li>
              <li>
                Para que possamos ofertar publicidades e indicar serviços de
                terceiros (por exemplo, os especialistas), caso você solicite;
              </li>
            </ol>

            <p>
              Agora vamos descrever em quais as circunstâncias podem haver
              compartilhamento dos seus dados pessoais:
            </p>
            <ol className="sub-list-roman">
              <li>Caso você queira, consinta e solicite;</li>
              <li>Para empresas afiliadas da Theia;</li>
              <li>
                Com a administração pública, se for solicitado e estiver dentro
                dos requisitos da lei (incluindo aqui autoridades e órgãos
                regulatórios), bem como com pessoas físicas ou jurídicas se for
                necessário no decorrer de uma ordem judicial ou para cumprimento
                de obrigação legal;
              </li>
              <li>
                Para resguardar os direitos da Theia, incluindo processos
                administrativos ou judiciais (por exemplo, para realizar uma
                denúncia de fraude ou dolo ocorrido na Plataforma);
              </li>
              <li>
                Com especialistas, que prestam serviços na Plataforma, caso você
                agende horários de atendimentos com eles, e o compartilhamento
                se dará para as finalidades previstas nesta Política. Neste
                compartilhamento com especialistas estes não terão o direito de
                utilizar suas informações além do aqui previsto, salvo de forma
                anônima e agregada, para que seja possível melhorarem a
                qualidade dos serviços por eles prestados;
              </li>
              <li>
                Caso ocorra alguma fusão, aquisição ou venda de ativos da Theia,
                parcialmente ou em sua totalidade, acarretando em uma troca de
                controle e propriedade, anteriormente em posse da Theia. Neste
                caso, a empresa que assumir o controle e propriedade terá acesso
                aos seus dados, sempre de acordo com a legislação vigente e
                transparência.
              </li>
              <li>
                Com prestadores de serviço, terceiros e parceiros comerciais que
                tratam informações em nome da Theia (por exemplo, mas não se
                limitando a: armazenamento de dados em nuvem, provedor de
                e-mail, empresas de repositórios de software e códigos,
                segurança cibernética, detecção de erros, empresas de
                comunicação com o cliente, empresas de métricas de acesso e
                estatísticas, empresas de meios de pagamento e emissores de
                notas fiscais). Nestes compartilhamentos, estes não terão o
                direito de utilizar suas informações além do aqui previsto,
                salvo de forma anônima e agregada, para que seja possível
                melhorarem a qualidade dos serviços por eles prestados;
              </li>
            </ol>

            <p>
              Ressaltamos, apesar de já termos indicado as finalidades
              anteriormente, que nos casos em que haja compartilhamento de seus
              dados sensíveis (em especial no caso "v", que ocorrerá de forma
              mais recorrente), mais detalhadamente seus dados de saúde, dados
              médicos e dados sobre vida sexual (se aplicável), este
              compartilhamento se dará unicamente para viabilizar a correta
              prestação de Serviços na Plataforma, e nos casos autorizados pela
              legislação vigente. Reforçamos que utilizamos os meios de
              segurança adequados para o tratamento de dados, em especial os
              dados pessoas sensíveis.
            </p>
            <p>
              Já no último caso da lista ("vii"), em específico, é importante
              ressaltar que é provável que você utilize os serviços de alguma
              dessas empresas de outras formas autônomas (por exemplo, você pode
              utilizar seu e-mail pessoal de uma plataforma que também usamos).
              Assim, deixamos claro que, nestes casos, o tratamento dos dados
              nesta relação entre você e a empresa, é de responsabilidade da
              empresa, e não nossa, ok?
            </p>
          </dd>

          <dt>
            Quais dados pessoais a Theia vai coletar e como eles vão ser
            utilizados?;
          </dt>
          <dd>

            <p>
              Esta é, sem dúvidas, uma das perguntas mais importantes a serem
              feitas. É muito pertinente que você questione essas informações,
              não apenas na Theia, mas em qualquer plataforma ou serviço que
              você fizer uso.
            </p>
            <p>
              Para facilitar a resposta e a compreensão, vamos separar esta
              pergunta em tópicos.
            </p>

            <dl>
              <dt className="roman-intern-dt">
                Quando a Theia vai coletar meus dados?
              </dt>
              <dd>
                <p>
                  Os dados pessoais (incluindo os que identificam você) vão ser
                  coletados em diferentes momentos, inclusive podendo ser de forma
                  contínua, por exemplo:
                </p>
                <ol className="sub-intern-list-lower-alpha">
                  <li>
                    Quando você acessa, utiliza ou interage de qualquer forma com a
                    Plataforma;
                  </li>
                  <li>
                    Quando você se cadastra, preenche informações e edita seu
                    perfil;
                  </li>
                  <li>Quando opta por um plano ou realiza pagamentos;</li>
                  <li>Quando entra em contato com o time de triagem;</li>
                  <li>
                    Quando solicita ou contrata um de nossos Serviços (e durante seu
                    uso também);
                  </li>
                  <li>Quando realiza atendimentos com especialistas;</li>
                  <li>
                    Quando autoriza o compartilhamento e coleta de suas informações;
                  </li>
                  <li>
                    Quando clica em links ou botões na Plataforma ou em mensagens ou
                    e-mails que te enviamos;
                  </li>
                  <li>
                    Quando trabalhamos com parcerias comerciais que possuem
                    informações sobre você, que são coletadas conforme as
                    finalidades descritas na pergunta 3 (a exemplo, mas não se
                    limitando a, <i>cookies</i>).
                  </li>
                </ol>
              </dd>
              <dt className="roman-intern-dt">
                Quais são as informações a serem coletadas?
              </dt>
              <dd>
                <p>As informações que a Theia poderá coletar são as:</p>
                <ol className="sub-intern-list-lower-alpha">
                  <li>
                    Sobre seus dados cadastrais e os identificadores, incluindo
                    dados de documentos pessoais;
                  </li>
                  <li>Sobre seu relacionamento, cônjuge e dependentes;</li>
                  <li>
                    Fornecidas no acesso à Plataforma e/ou contratação de um Serviço
                    dentro da mesma;
                  </li>
                  <li>
                    Obtidas (coletadas ou fornecidas) mediante uso da Plataforma ou
                    Serviço;
                  </li>
                  <li>Sobre sua localização;</li>
                  <li>Estatísticas;</li>
                  <li>Recebidas de terceiros sobre você;</li>
                  <li>
                    <i>Cookies</i>, SDKs, <i>web beacons</i> ou tecnologias semelhantes (há um
                    tópico e uma política específicos para isso!);
                  </li>
                  <li>
                    De saúde e seus hábitos, incluindo plano de saúde, histórico
                    médico, queixas, exames, conduta, diagnósticos e predisposições;
                  </li>
                  <li>
                    Referentes ao seu dispositivo que utiliza para acessar a
                    Plataforma ou algum Serviço;
                  </li>
                  <li>Informações sobre formas de pagamento;</li>
                </ol>
              </dd>
              <dt className="roman-intern-dt">
                  Ok, mas é possível deixarmos ainda mais claro quais são os dados
                  coletados?
              </dt>
              <dd>
                <p>
                  Sim, sim. Deixamos claro que nem todos esses dados são
                  obrigatórios, ou seja, alguns deles você pode optar por não nos
                  fornecer caso não queira ou não se aplique, mas lembramos que
                  quanto mais completo o perfil, maiores as chances de propiciarmos
                  uma experiência melhor para você. Já os dados obrigatórios são os
                  mínimos para garantir a execução dos nossos serviços.
                </p>

                <p>
                  A solicitação do fornecimento ou a coleta destes dados pode
                  variar, não necessariamente ocorrendo no ato do cadastro, sendo
                  que, também é possível que coletemos alguns desses dados de bases
                  públicas ou via parceiros da Theia (a exemplo dos especialistas,
                  ou caso a empresa que você trabalhe contrate a Theia como um
                  benefício corporativo). Enfim, vamos lá:
                </p>

                <ol className="sub-intern-list-lower-alpha">
                  <li>
                    <b>Dados de natureza técnica:</b> são os dados ou de natureza técnica
                    (pedimos perdão pela redundância) ou estatísticos, que são
                    atrelados a um determinado Serviço ou aplicações da Plataforma (ou
                    da Plataforma como um todo) e, dentro desse grupo de dados,
                    enquadram-se: endereço de IP, identificador do seu dispositivo
                    (marca, modelo e número próprio), tipo de navegador, sistema
                    operacional (celular, computador ou outro dispositivo), versão
                    utilizada do aplicativo de celular (se aplicável), informações
                    sobre localização geográfica, uso e interações com a Plataforma e
                    Serviços, informações sobre visualização ou acesso aos anúncios
                    divulgados na plataforma ou conteúdo exibido em sites ou
                    aplicativos de terceiros, tempo de sessão, data de criação da
                    conta e dias e quantidade de acessos.
                  </li>
                  <li>
                    <p>
                      <b>Dados de cadastro e identificadores:</b> são os dados que utilizaremos
                      para te identificar e permitir que você acesse nosso serviço.
                      Dentro deste grupo de dados, encontram-se: nome completo, endereço
                      completo, nome social (ou apelido, ou como preferir que te
                      chamemos), data de nascimento, idade, e-mail pessoal, número de
                      telefone (celular preferencialmente), número do RG e CPF, sexo,
                      informações sobre gravidez, data esperada do parto, preferência
                      por tipo de parto, filiação, profissão, estado civil, período de
                      trabalho, empresa onde trabalha, se a empresa que trabalha é
                      vinculada à Theia, dados bancários e de cartão de crédito,
                      informações do plano de saúde do qual faça parte, valor de
                      reembolso de consultas do plano de saúde do qual faça parte.
                    </p>
                    <p>
                      É possível pedirmos comprovação da veracidade de alguns desses
                      dados (por exemplo, enviar comprovante de residência ou foto de
                      documento de identificação), ok? Ah, e alguns desses dados podem
                      ser coletados por bases públicas ou mediante parceiros.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Dados de perfil, complementares e demográficos:</b> neste grupo também
                      há dados que podem ser coletados por bases públicas ou serem
                      fornecidos por parceiros. Apesar de alguns dados também serem, na
                      essência, de cadastro e identificadores, esse grupo é mais voltado
                      para personalização e prestação dos Serviços na Plataforma. Entre
                      eles, enquadram-se: o seu gênero, relacionamento, data do início
                      do relacionamento ou tempo do relacionamento, foto pessoal, nome
                      do cônjuge, data de nascimento do cônjuge, idade do cônjuge,
                      e-mail do cônjuge, telefone cônjuge, gênero do cônjuge, sexo
                      cônjuge, se tem filhos e quantidade, data de nascimento dos
                      filhos, nome dos filhos, sexo e gênero dos filhos, foto dos
                      filhos, renda, religião (há religiões que possuem restrições
                      quanto a tratamentos e métodos de saúde modernos), depoimentos
                      sobre a Theia (enviados por nossos canais ou postado em redes
                      sociais), origem racial, dificuldades apresentadas na convivência
                      familiar ou no ambiente corporativo e quais conteúdos/temas te
                      interessam.
                    </p>
                    <p>
                      Outra coisa que temos que deixar claro: a possibilidade de coleta
                      e tratamento dos dados de menores de idade (crianças e
                      adolescentes) só é possível após expressa autorização do
                      responsável legal do menor. Também, obviamente, só poderemos
                      realizar os tratamentos de acordo com as finalidades presentes na
                      autorização.
                    </p>
                  </li>
                  <li>
                    <b>Dados de saúde em geral, incluindo os médicos e de bem-estar:</b>
                    alguns destes dados podem ser coletados no cadastro, na
                    contratação de um Serviço, em conversa com o time de triagem ou
                    durante um atendimento com especialista. Dentro deste grupo de
                    dados, estão: seu peso, altura, tipo sanguíneo, informações sobre
                    o ciclo menstrual (se aplicável), uso de álcool, tabagismo,
                    existência de vícios, alergias, preexistência de doenças e lesões,
                    histórico de antecedentes médicos, prontuário médico, recibos e
                    notas fiscais de consultas e procedimentos médicos, restrições
                    alimentares, hábitos alimentares e nutricionais, informações sobre
                    a qualidade do sono, queixas de saúde, histórico e predisposição
                    familiar a problemas de saúde, histórico de internações, conduta
                    médica, hábitos de atividade física, uso de medicamentos
                    controlados ou de uso contínuo, histórico de atendimentos na Theia
                    (incluindo consultas), informações sobre exames e diagnósticos e
                    informações sobre o bem-estar em geral.
                  </li>
                </ol>
              </dd>

              <dt className="roman-intern-dt">
                E os cookies?
              </dt>
              <dd>
                <p>
                  Os <i>cookies</i>, SDKs, <i>web beacons</i> (ou outros nomes) são, basicamente,
                  arquivos que armazenam, de forma temporária, o que você está
                  visitando. Nós podemos utilizá-los sim, e temos uma política
                  específica deles, que pode ser acessada&nbsp;
                  <a
                    href="https://theia.com.br/cookies"
                    target="_blank"
                    className="cookies-link"
                  >
                    aqui
                  </a>
                  .
                </p>
                <p>
                  Nossa Política de <i>Cookies</i>, <i>Web Beacons</i>, Publicidade e Outras
                  Tecnologias (como a chamamos) é parte integrante da Política de
                  Privacidade. A colocamos de forma separada pois ela apresenta
                  algumas peculiaridades próprias, no caso de você não quiser que
                  coletemos este tipo de informações (você pode gerenciar suas
                  preferências diretamente no <i>site</i> dos navegadores).
                </p>
              </dd>
              <dt className="roman-intern-dt">
                  Que outras formas a Theia pode coletar minhas informações a partir
                  de terceiros?
              </dt>
              <dd>
                <p>
                  Comentamos anteriormente que havia essa possibilidade, mas vamos
                  detalhar um pouco mais. Caso as informações (ou seus dados) tenham
                  sido obtidos de forma legal, em conformidade com a legislação
                  aplicável, e caso você tenha anuído (dado consentimento) para o
                  compartilhamento, é possível sim recebermos estes dados de fontes
                  terceiras. Por exemplo: podemos obter informações a partir da
                  empresa que você trabalha, caso ela tenha um contrato firmado com
                  a Theia (veja essa possibilidade nos nossos Termos de Uso); com o
                  time de especialistas que presta serviço para a Theia; a partir de
                  bases e bancos de dados públicos e privados; ou mesmo com outros
                  prestadores de serviço.
                </p>
                <p>
                  Mas então você pode estar se perguntando como fica a
                  responsabilidade do tratamento de dados nesta situação. Bom, é
                  simples. As finalidades que utilizamos e as situações que
                  compartilhamos seus dados são claras, está tudo escrito aqui nesta
                  Política. As informações que recebemos de terceiros serão
                  associadas às informações que coletamos de você (fornecidas ou
                  coletadas por nós) e então, o resultado desta associação estará
                  sujeito ao que está escrito aqui na Política de Privacidade – isso
                  quer dizer que não cabe à nós o que estiver fora deste âmbito.
                </p>
              </dd>

              <dt className="roman-intern-dt">
                E se eu quiser atualizar meus dados?
              </dt>
              <dd>
                <p>
                  E caso você se pergunte se você pode atualizar seus dados, a
                  resposta é sim, você pode (e deve) manter seus dados atualizados.
                  Inclusive, é um dos pontos que constam nos seus direitos (ver a
                  pergunta 9). Caso seus dados sofram qualquer alteração, ou caso
                  você perceba que eles não estão perfeitamente precisos, você
                  poderá atualizá-los.
                </p>
              </dd>

              <dt className="roman-intern-dt">
                E se quiser enviar um <i>feedback</i>?
              </dt>
              <dd>
                <p>
                  Está gostando? Gostaria de nos enviar um <i>feedback</i>, idéias ou
                  sugestões? São sempre bem-vindos e podem ser feitos via nossos
                  canais de comunicação ou via pesquisas que disponibilizaremos
                  esporadicamente – além de serem excelentes para melhorar a
                  experiência dos usuários, nos fazem amadurecer como empresa, e nos
                  ajudam também em nossa divulgação, pois os depoimentos reais de
                  usuários fazem muita diferença na divulgação de uma marca. Ou
                  seja, isto quer dizer que, caso você nos envie um <i>feedback</i> ou
                  depoimento, você também está enviando, automaticamente, uma
                  autorização do direito de utilizarmos, gratuitamente, seu <i>feedback</i>&nbsp;
                  ou depoimento publicamente em nossa Plataforma ou comunicação,
                  seja ela qual for. Entretanto, deixamos claro que utilizaremos seu
                  &nbsp;<i>feedback</i>&nbsp; ou depoimento de forma anônima, sempre respeitando os
                  Termos, a Política, a legislação vigente e, principalmente, você.
                </p>
              </dd>
            </dl>
          </dd>

          <dt>
            E os dados pessoais sensíveis?;
          </dt>
          <dd>
            <p>
              Referem-se aos dados conforme falamos na primeira pergunta, e são
              chamados de sensíveis pois são realmente sensíveis (perdoem-nos da
              redundância) e delicados, pois, nas mãos erradas, podem trazer
              sérios problemas ao titular deles, incluindo, mas não se limitando
              a: discriminação e assédio (infelizmente há pessoas de má índole
              que se aproveitam desse tipo de informação para promover o ódio e
              se beneficiarem disso). Nesta lista de dados sensíveis, também são
              incluídos os dados de crianças e adolescentes (menores de idade,
              ou seja, menores de 18 anos). A possibilidade de coleta e
              tratamento dos dados de menores de idade só é possível após
              expressa autorização do responsável legal do menor. Claro, só
              poderemos realizar os tratamentos de acordo com as finalidades
              presentes na autorização.
            </p>
            <p>
              Novamente, para podermos realizar a prestação dos nossos Serviços
              a você, <u>é essencial também que tenhamos acesso aos seus dados
              pessoais sensíveis</u>, incluindo os dados de saúde, médicos, sobre a
              vida sexual e o gênero, sempre mantendo-os seguros e respeitando
              principalmente você, mas também a legislação vigente e o sigilo
              profissional, evitando qualquer incidente possível (acesso ou uso
              indevido, violação, divulgação ou destruição desses dados). Com
              seus dados pessoais sensíveis podemos prestar Serviços de melhor
              qualidade, com coordenação de cuidados com uma equipe
              multidisciplinar, de forma mais personalizada e humana. Em outras
              palavras, os utilizaremos para cumprir nossa obrigação com você
              (executar o contrato), garantindo uma boa tutela de saúde, com seu
              consentimento.
            </p>

            <p>
              Não utilizaremos seus dados pessoais sensíveis para estratégias de
              marketing, comerciais, publicitárias, comercialização de serviços
              e produtos próprios ou terceiros, e muito menos faremos avaliações
              de risco e precificação direcionados, baseado nos seus dados
              pessoais sensíveis fornecidos. Eventualmente, podemos utilizar
              esses dados com o intuito de realizar estudos de saúde para
              melhorar a qualidade, abrangência e eficiência dos nossos Serviços
              prestados a você.
            </p>
          </dd>

          <dt>
            Como será feito o armazenamento e segurança dos dados? Também, por
            quanto tempo eles ficarão armazenados?;
          </dt>
          <dd>

            <p>
              Para que possamos prestar corretamente nossos Serviços a você,
              precisamos dos seus dados pessoais. Assim, uma das nossas
              prioridades máximas é manter seus dados seguros. Para isso,
              contamos com medidas de segurança técnicas, administrativas e
              física, seguindo sempre as melhores práticas de mercado e as
              legislações vigentes. Algumas destas medidas são: controles de
              acesso (inclusive nos computadores, rede de Theia, <i>softwares</i>&nbsp;
              utilizados e bases de dados), restringindo quem pode acessar quais
              informações; validação em dois fatores quando possível; <i>firewalls</i>;
              criptografia da base (no Backend e no armazenamento em nuvem);
              monitoramento de segurança em tempo real (o tempo todo); sistemas
              de missão crítica; protocolos de segurança; análise de
              antecedentes de novos colaboradores; além de cumprir com as
              exigências de PCI (<i>Payment Card Industry</i>) <i>Data Security Standards</i>,
              para compras com cartão de crédito na Plataforma; existência de&nbsp;
              <i>backup</i>; entre outros.
            </p>
            <p>
              Ainda segundo a segurança, é importantíssimo
              deixarmos claro que a responsabilidade da Theia e nosso empenho é
              referente aos dados a partir do momento que eles são coletados por
              nós. Não podemos (obviamente) nos responsabilizar por quaisquer
              ataques ou problemas (de caráter acidental ou não) que venham a
              ocorrer em seus dispositivos, em decorrência do seu uso, ou das
              redes a que você se conectar.
            </p>
            <p>
              Quanto ao armazenamento dos dados
              coletados, nós podemos armazenar as informações diretamente pela
              Theia e/ou utilizar prestadores de serviço terceirizados
              habilitados para tal (ver a pergunta 7), sempre avaliando e
              monitorando sua conduta, exigindo que os mesmos sigam nossa
              Política de Privacidade, padrões de segurança e confidencialidade.
            </p>
            <p>
              Em relação ao tempo que eles ficam armazenados em posse da Theia,
              este tempo pode variar, dado que temos que mantê-los, pelo menos,
              pelo tempo que: você tiver vínculo com a Plataforma Theia, para
              podermos oferecer nossos Serviços de forma adequada à você; for
              necessário para cumprir com as finalidades descritas
              anteriormente; cumprir os requisitos legais, regulatórios e
              contábeis (exemplificando, aqui, a Lei 12.965/2014, o conhecido
              "Marco Civil da Internet"); e, também, pelo prazo que for
              necessário e seguro para que possamos lidar com quaisquer
              eventuais reivindicações e citações judiciais.
            </p>
            <p>
              Você verá mais
              adiante (na pergunta 9) que um dos direitos dos titulares é
              solicitar a exclusão dos seus dados pessoais. Na ocasião da
              exclusão, ou destruiremos seus dados ou os anonimizaremos (nos
              termos já definidos na primeira pergunta), não havendo,
              entretanto, exigência por lei de que deva ocorrer um ou outro
              especificamente, ficando, à único e exclusivo critério da Theia, a
              decisão. Neste caso, como não teremos mais seus dados pessoais,
              não poderemos mais oferecer nossos Serviços a você.
            </p>
            <p>
              Ainda segundo
              a exclusão de dados, a LGPD prevê algumas hipóteses em que é
              autorizada a manutenção dos dados por nós (artigo 16), como é o
              caso de cumprimento de obrigação legal ou regulatória por parte da
              Theia, ou seja, mesmo que você nos solicite, pode ser que tenhamos
              que manter ao menos alguns de seus dados, até findo o prazo legal
              ou até ordem da autoridade competente. Também, podemos (e devemos)
              manter seus dados conosco até que tenhamos cumprido todas as
              obrigações entre nós, sendo elas resolvidas amigavelmente ou não.
              Na eventualidade de você nos solicitar a exclusão, e caso não
              tenhamos pendências ou obrigações legais/regulatórias a serem
              cumpridas, faremos a exclusão dos dados.
            </p>
          </dd>

          <dt>
            E esses tratamentos serão somente no Brasil, no ambiente Theia?;
          </dt>
          <dd>

            <p>
              Não! A Theia poderá tratar seus dados pessoais (incluindo
              armazenamento, transferência, utilização), sendo estes sensíveis
              ou não, tanto no Brasil quanto no exterior. Independente de onde
              seja o local, vamos sempre garantir o cumprimento tanto da
              legislação vigente quanto desta Política. Afinal, precisamos
              proteger os dados que você nos confiou.
            </p>
            <p>
              Para exemplificar onde
              seus dados se enquadram na Transferência Internacional de Dados
              (que é a terminologia descrita na LGPD) é fácil: a Theia é uma
              empresa de tecnologia que almeja crescer de forma escalável, com
              qualidade, segurança, e otimizando ao máximo a performance e
              eficiência. Assim, uma das soluções adotadas é utilização de
              programas de armazenamento de informações em nuvem. Atualmente, as
              ditas melhoras empresas neste ramo (em questão de segurança de
              dados, inclusive), não são brasileiras. Ou seja, ao utilizar uma
              solução dessas, em que seus dados são armazenados fora do país, há
              a chamada Transferência Internacional de Dados. Esta situação se
              aplica a diversos prestadores nacionais e internacionais.
              Ressaltamos que seus dados pessoais (sensíveis ou não)
              transferidos internacionalmente para armazenamento e tratamento,
              sempre serão para prestadores de serviço que agirão de acordo com
              nossas instruções e em conformidade com as legislações vigentes,
              mesmo que as leis sobre proteção de dados e privacidade variem de
              país para país.
            </p>
            <p>
              É possível que você não esteja confortável com a
              Transferência Internacional de Dados, mas, infelizmente, aceitá-la
              é uma condição necessária para o aceite completo da presente
              Política. Assim, caso você não concorde com este ponto (ou com
              qualquer outro aqui da Política, reforçamos isso sempre), você não
              deverá utilizar os Serviços da Theia, mas sempre estamos à
              disposição para esclarecer eventuais dúvidas e tentar sanar as
              inseguranças.
            </p>
          </dd>

          <dt>
            O que a Theia vai fazer quando quiser alterar ou atualizar esta
            Política?;
          </dt>
          <dd>
            <p>
              É natural que, com o tempo, as informações, necessidades e foco da
              Theia podem mudar, bem como a legislação vigente, o que refletiria
              diretamente na Política. Caso haja alterações ou atualizações
              desta Política de Privacidade, elas estarão sempre disponíveis
              neste link, e você poderá, a qualquer hora, confirmar a data da
              última atualização sempre na primeira página, logo abaixo do
              título. Na eventualidade de realizarmos alguma mudança grande que
              não envolva alteração dos dados pessoais, ou mesmo uma simples
              mudança dos tipos de dados pessoais (sensíveis ou não) e
              finalidades previstas para seu uso, a Theia entrará em contato com
              você para comunicar destas alterações e da disponibilização da
              nova versão da Política. Lembrando que você só deverá utilizar ou
              continuar utilizando a Plataforma caso esteja de acordo com a
              plenitude da Política, ok?
            </p>

          </dd>

          <dt>Quais os direitos do titular dos dados?</dt>
          <dd>
            <p>
              Deixamos este ponto para o final, mas ele não é menos importante
              do que os outros. Novamente, estamos falando dos seus dados
              pessoais. Com o intuito de você se sentir com segurança para os
              tratamentos de dados realizados pela Theia, e também para cumprir
              a LGPD, listamos abaixo seus direitos de requisições, conforme
              previsto na legislação (mais informações podem ser obtidas no
              artigo 18 da LGPD):
            </p>
            <ol className="sub-list-lower-alpha">
              <li>
                Confirmação da existência de tratamento de dados pessoais;
              </li>
              <li>Acesso aos dados pessoais tratados;</li>
              <li>
                Correção de dados incompletos, inexatos ou desatualizados;
              </li>
              <li>
                Anonimização (desassociá-los do titular dos dados), bloqueio ou
                eliminação de dados desnecessários, excessivos ou tratados em
                desconformidade com o disposto tanto na presente Política quanto
                na própria LGPD;
              </li>
              <li>
                Revogação do seu consentimento para o tratamento de dados
                pessoais, quando os dados foram coletados a partir de seu
                consentimento;
              </li>
              <li>
                Exclusão dos dados pessoais obtidos a partir do seu
                consentimento (exceto nas hipóteses previstas pela LGPD);
              </li>
              <li>
                Informação das entidades públicas e privadas com as quais a
                Theia realizou uso compartilhado dos dados pessoais que você nos
                forneceu.
              </li>
            </ol>
          </dd>

          <dt>Transferência de Dados para Terceiros</dt>
          <dd>
            <p>
            Ao aceitar essa Política de Privacidade, você concorda que a Theia
            poderá compartilhar seus dados com outras entidades, como empresas
            de armazenamento em nuvem, agências de marketing, consultorias para
            a análise comercial e estatística dos dados anonimizados e operadoras
            de planos de saúde, caso você esteja vinculado a uma. A orientação
            a estes terceiros será sempre sobre como tratar seus dados, mantê-los
            seguros, cumprir os termos indicados neste documento e cumprir as
            legislações vigentes.
            </p>
          </dd>
        </dl>
        <p>
          Caso você queira atualizar seus dados, exercer algum destes
          direitos ou mesmo tirar dúvidas a respeito desta Política, poderá
          enviar um e-mail para dpo@theia.com.br que o Henrique, nosso
          responsável pelo tratamento de dados na Theia (também chamado de
          DPO – Data Protection Officer), irá te responder.
        </p>
        <p>
          Esperamos, sinceramente, que esta Política tenha sido
          esclarecedora, e que sua experiência com a Theia seja incrível!
        </p>
      </div>
    </Layout>
  )
}

export default Policy






