import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export default function trackEvent(eventName: string) {
  if (navigator.userAgent.toLowerCase().trim().indexOf("ghost") === -1) {
    trackCustomEvent({
      category: "All",
      action: eventName,
    })    
  }
}
