import trackEvent from "./analyticsHelpers"

export function getWindowLocationPath() {
  let path
  if (typeof window !== 'undefined') {
    path = window.location.pathname.split('/')
    return path[1]
  } 
}

export const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]

export const goToApp = (path: string, event?: string) => {
  trackEvent(event)
  location.href = path
}

export function getAndroidOperatingSystem() {
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    let userAgent = window.navigator.userAgent || window.navigator.vendor
    if (/android/i.test(userAgent)) {
      return true
    } else {
      return false
    }
  }
}